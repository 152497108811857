import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Row, Col, Container, Carousel, Form } from 'react-bootstrap';
import { etoast, makeValid, postMan, toast, translateBackendMessage, validateSecurityCode, getNewCode, getLocale, parseLocaleURL } from '../services/utils';
import MetaTags from 'react-meta-tags';
import LanguageMenu from "../components/LanguageMenu";
import CookieBanner from "../components/CookieBanner";
import environments from '../environments/environments';
import { FormattedMessage, useIntl } from 'react-intl';
import { HashLink } from "react-router-hash-link";
import LogoClean from './../assets/img/logo-clean.png'
import { intlGlobal, UserContext } from "../index.js";
import { faWindows } from "@fortawesome/free-brands-svg-icons";

const SecurityCode = (props) => {
  const intl = useIntl();
  const user = useContext(UserContext);

  const setUser = (props) => {
    user.setUser(
        {
            id: props.id,
            first_name: props.first_name,
            last_name: props.last_name,
            email: props.email
        })
}

  useEffect(
    () => {
        Promise.resolve(getNewCode(user.email));
        return (
        window.onpopstate = (e) => {
          user.setUser(
            {
                id: null,
                first_name: null,
                last_name: null,
                email: null
            });
        });
    },[]
  );
  
  return (
    <>
    <MetaTags>
      <title>{intlGlobal.formatMessage({id: "security-code.header"})}</title>
      <meta name="description" content={intlGlobal.formatMessage({id: "security-code.description"})} />
    </MetaTags>
      <Row className="h-100 w-100 m-0 p-0">
        <Col className="h-100 col-lg-4 col-md-4 col-12 col-sm-12 m-0 p-0 d-none d-sm-block">
        <Carousel fade={true} >
            <Carousel.Item className="vh-100 carousel-img-1">
              <Carousel.Caption>
                <h3><FormattedMessage id="security-code.carousel.title1" /></h3>
                <p><FormattedMessage id="security-code.carousel.caption1" /></p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="vh-100 carousel-img-2">
              <Carousel.Caption>
                <h3><FormattedMessage id="security-code.carousel.title2" /></h3>
                <p><FormattedMessage id="security-code.carousel.caption2" /></p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="vh-100 carousel-img-3">
              <Carousel.Caption>
                <h3><FormattedMessage id="security-code.carousel.title3" /></h3>
                <p><FormattedMessage id="security-code.carousel.caption3" /></p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col className="bg-white col-12 col-sm-12 col-md-8 pt-4">
          <div className="h-100 d-flex justify-content-center align-items-center">
            <SecurityCodeBox intl={intl} user={user} />
          </div>
        </Col>
      </Row>
      <LanguageMenu />
      <CookieBanner />
    </>
  );
}

const SecurityCodeBox = (props) =>{
    const intl = props.intl; 
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [errorCode, setErrorCode] = useState(false);

    const captchaRef = useRef();
    const codeRef = useRef();
    const user = props.user;

    const logoutAndGoBack = () => {
      // postman
      const logout = async() => {
        const [resp,error] = await postMan("logout_user","POST",{ 
          data: {}, 
        });
        return [resp, error];
      }
      Promise.resolve(logout());
      // set user null
      user.setUser(
        {
            id: null,
            first_name: null,
            last_name: null,
            email: null
        })
      goBack();
    }

    const validateForm = () => {
        let ret = true;
        if(!codeRef.current.value || !validateSecurityCode(user.email, codeRef.current.value))
        {
            setErrorCode(true);
            ret = false;
        }

        return ret;
    }

    const generateNewCode = async() => {
      const [resp,error] = await getNewCode(user.email);
      if(error)
      {
          etoast(intl.formatMessage({id: 'error.internal'}));
          return setLoading(false);
      }
      const response = resp.data.result;

      if(response.error_msg)
      {
          etoast(response.error_msg)
          return setLoading(false);
      }

      if(response.success_msg)
      {
          setLoading(false);
          toast(translateBackendMessage(response.success_msg));
      }

      setLoading(false);
    }

    const authorize = async (e) => {
        e.preventDefault();

        if(!validateForm())
            return false;

        setLoading(true);

        const [resp,error] = await postMan("validate_security_code","POST",{ 
            data: {
                email: user.email,
                code: codeRef.current.value
            }, 
            token: "",
        });
        if(error)
        {
            etoast(intl.formatMessage({id: 'error.internal'}));
            return setLoading(false);
        }
        const response = resp.data.result;

        if(response.error_msg)
        {
            etoast(response.error_msg)
            return setLoading(false);
        }

        if(response.success_msg)
        {
            setLoading(false);
            toast(translateBackendMessage(response.success_msg));
            window.location.assign(parseLocaleURL());
        }

        setLoading(false);
    }

    const goBack = () => {
        navigate("/login");
    }
  
  return(
    <div className="mx-auto col-sm-12 col-md-10 col-lg-9">
      <Container>
        <Row className="justify-content-center">
          <Col lg="12" md="12">
            <HashLink to="/#header"><img src={LogoClean} alt="Invoice cash factoring logo" className="app-logo" /></HashLink>
            <h4 className="mb-0 mt-3">
              <div><FormattedMessage id="security-code.header" /></div>
            </h4>
            <span className="pt-2"><FormattedMessage id="security-code.header-lead" /></span>

            <hr className="mb-3" />
            <Form>
              <Row>
                <Col md={12}>
                    <Form.Group controlId="formBasicCode" className={errorCode ? `has-danger` : ``}>
                        <Form.Control isInvalid={errorCode} type="text" placeholder={intl.formatMessage({id: 'security-code'})} ref={codeRef} required minLength="6" maxLength="6" />
                        <Form.Control.Feedback type="invalid" >
                          <FormattedMessage id="error.required" />
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={12}><hr style={{marginTop: "0"}} /></Col>
                <Col md={12} className=" d-flex justify-content-between">
                    <Button variant="outline-primary" onClick={() => logoutAndGoBack()}>
                    <span id="reg_text">
                      <FormattedMessage id="security-code.back" />
                    </span>
                    </Button>
                    <Button variant="outline-primary" onClick={() => generateNewCode()}>
                    <span id="reg_text">
                      <FormattedMessage id="security-code.generate-new" />
                    </span>
                    </Button>
                    {
                    loading ? 
                      <Button
                        className="btn-round"
                        color="default"
                        type="submit"
                        disabled>
                        <FormattedMessage id="loading" />
                        </Button>
                        :
                        <Button
                            className="btn-round"
                            color="default"
                            type="submit"
                            onClick={(event) => authorize(event)}>
                        <FormattedMessage id="security-code.authorize" />
                      </Button>
                    }
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SecurityCode;
